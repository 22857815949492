<template>
  <b-card title="Расширенная информация" class="card-small extended-information">
    <div v-if="item">
      <div>
        <div v-if="item.application_id" class="mb-2">
          <p class="font-weight-bold my-0">ID заявки</p>
          <div class=" ">{{ item.application_id }} <clipboard :data="item.application_id"/></div>
        </div>
        <div class="mb-2" v-if="item.order.settlement_order_id">
          <p class="font-weight-bold my-0">Номер заказа</p>
          <div class=" ">{{ item.order.settlement_order_id }} <clipboard :data="item.order.settlement_order_id"/></div>
        </div>
        <div class="mb-2" v-if="item.purchase_amount">
          <p class="font-weight-bold my-0">Сумма заказа</p>
          <div class=" ">{{ properM(item.purchase_amount) | formatNumber }} <span v-html="currency(item.currency_id)"></span> <clipboard :data="properM(item.purchase_amount) | formatNumber"/></div>
        </div>
        <div class="mb-2" v-if="item.max_discount.discount">
          <p class="font-weight-bold my-0">Максимальный дисконт</p>
          <div class=" ">{{ item.max_discount.discount | format_discount | formatNumber }}</div>
        </div>
        <div v-if="item.purchase_amount" class="mb-2">
          <p class="font-weight-bold my-0">Сумма к перечислению</p>
          <div class=" ">{{ item.settlement_amount ? properM(get_settlement_amount(item.settlement_amount)) : properM(get_settlement_amount(item.purchase_amount, item.max_discount.discount)) | formatNumber }}
            <span v-html="currency(item.currency_id)"></span> <clipboard :data="item.settlement_amount ? properM(get_settlement_amount(item.settlement_amount)) : properM(get_settlement_amount(item.purchase_amount, item.max_discount.discount)) | formatNumber "/>
          </div>
        </div>
        <div v-if="item.purchase_amount" class="mb-2">
          <p class="font-weight-bold my-0">Сумма выручки</p>
          <div class=" ">{{ item.merchant_revenue_amount ? properM(get_settlement_amount(item.merchant_revenue_amount)) : '-' | formatNumber }}
            <span v-html="currency(item.currency_id)"></span> <clipboard :data="item.merchant_revenue_amount ? properM(get_settlement_amount(item.merchant_revenue_amount)) : '-' | formatNumber "/>
          </div>
        </div>
        <div v-if="item.offer.maturity_in_months" class="mb-2">
          <p class="font-weight-bold my-0">Одобренный срок</p>
          <div class=" ">{{ item.offer.maturity_in_months }} мес.</div>
        </div>
        <div v-if="item.offer.lender_name" class="mb-2">
          <p class="font-weight-bold my-0">Название кредитора</p>
          <div class=" ">{{ item.offer.lender_name }}</div>
        </div>
        <div v-if="item.offer.lender_agreement_number" class="mb-2">
          <p class="font-weight-bold my-0">Номер договора кредитора</p>
          <div class=" ">{{ item.offer.lender_agreement_number }} <clipboard :data="item.offer.lender_agreement_number"/></div>
        </div>
        <div class="mb-2" v-if="item.merch_status_description">
          <p class="font-weight-bold my-0">Детальный статус</p>
          <div class=" ">{{ item.merch_status_description }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="!item" class="text-center" style="line-height: 25em;">
      <p>Выберите заявку</p>
    </div>
  </b-card>
</template>

<script>
export default {
  props: ['item'],
  filters: {
    format_discount: function (value) {
      return Math.round(value*100)/100 + '%'
    },
    formatNumber: function (value) {
      if (!value) return '';
      return value.toString().replace('.', ',');
    }
  },
  computed:{
  },
  methods:{
    get_settlement_amount(amount, discount=0) {
      return Math.round((amount * (100 - discount) / 100) * 100) / 100
    }
  }
}
</script>
